var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({ name: 'deposits-create' })}}},[_c('i',{staticClass:"bi bi-plus-square fs-5"}),_vm._v(" Créer un nouveau dépôt ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.deposits),function(deposit,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(deposit.reference)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.fullName)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.responsable)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.phoneResponsable)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.address)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.city)+" ")]),_c('td',[_vm._v(" "+_vm._s(deposit.zipCode)+" ")]),_c('td',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'deposites-edit',
                params: {
                  reference: deposit.reference,
                },
              })}}})])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Responsable")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Nº de téléphone")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Adresse")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Ville")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Code postale")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }