<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'deposits-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square fs-5"></i> Créer un nouveau dépôt
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Désignation</th>
          <th scope="col">Responsable</th>
          <th scope="col">Nº de téléphone</th>
          <th scope="col">Adresse</th>
          <th scope="col">Ville</th>
          <th scope="col">Code postale</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(deposit, index) in deposits" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ deposit.reference }}
          </td>
          <td>
            {{ deposit.fullName }}
          </td>
          <td>
            {{ deposit.responsable }}
          </td>
          <td>
            {{ deposit.phoneResponsable }}
          </td>
          <td>
            {{ deposit.address }}
          </td>
          <td>
            {{ deposit.city }}
          </td>
          <td>
            {{ deposit.zipCode }}
          </td>
          <td>
            <button
              @click="
                $router.push({
                  name: 'deposites-edit',
                  params: {
                    reference: deposit.reference,
                  },
                })
              "
              class="btn"
            ></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("deposit", {
      deposits: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("deposit/getAll");
  },
  methods: {
    async refresh() {
      return await this.$store.dispatch("deposit/getAll");
    },
  },
};
</script>
